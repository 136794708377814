import $ from 'jquery';
import Mapper from '../wrappers/mapper';

$(function () {

  $('.insurancecompany-list-item-link').click(function (e) {
    var url = $(this).attr('href');
    console.log(url)
    e.preventDefault();
    $.ajax({
      method: 'get',
      url: url,
      success: function (data) {
        var sc = $(data).find('script.map-loader');
        $('.insurance-popup-content').html($(data).find('article.insurancecompany'));
        let map = new Mapper('insurance-map', false);
        map.getInsurerLocations(url);
        eval(sc.text());
        $('.insurance-popup').animate({
          opacity: 1
        }, {
          duration: 300,
          complete: function () {
            $('.insurance-popup').css('pointer-events', 'all')
            $('body').css('overflow', 'hidden');
          }
        })
      }
    })
  });

  $('.insurance-popup').click(function (e) {
    if ($(e.target).hasClass('insurance-popup')) {
      $(this).animate({
        opacity: 0
      }, {
        duration: 300,
        complete: function () {
          $('.insurance-popup').css('pointer-events', 'none')
          $('body').css('overflow', 'auto');
        }
      })
    }
  });

  $(document).on('click', '.insurance-popup-content .insurancecompany i.fa-times', function () {
    $('.insurance-popup').trigger('click')
  })
});

