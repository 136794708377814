import $ from 'jquery';
import Mapper from '../wrappers/mapper';


$(function(){
  if(typeof asnLocation !== 'undefined') {
    const locationMap = new Mapper("location-map", false, asnLocation.lat, asnLocation.lng, 13);
    locationMap.getSingleLocation(asnLocation.id);
  }

  if($('.video_sec').find('h3').length >= 2) {
    let height = 0;
    $('.video_sec').find('h3').each(function(){
      if($(this).height() > height) {
        height = $(this).height();
      }
    });
    $('.video_sec').find('h3').height(height);
  }

});