import $ from 'jquery';

$(function() {

  $('input[data-regex]').on('keyup', function() {
    let regEx = new RegExp($(this).attr('data-regex'));
    if($(this).hasClass('dirty') && regEx.test($(this).val())) {
      $(this).removeClass('is-invalid').addClass('is-valid');
    }
  });

  $('input[data-regex]').on('blur', function() {
    let regEx = new RegExp($(this).attr('data-regex'));
    if(!regEx.test($(this).val()) && $(this).val() !== '') {
      $(this).addClass('is-invalid dirty').removeClass('is-valid');
    }
  })

});